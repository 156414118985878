<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <delete-prompt
        :active="isDeleteModalVisible"
        @close="isDeleteModalVisible = false"
      >
        <div slot="content">
          <delete-automation
            :automation-id="automation_id"
            :automations="automations.data"
            @updateAutomation="updateAutomation"
          />
        </div>
      </delete-prompt>

      <prompt
        :active="isRerunVisible"
        :title="'Rerun automation'"
        @close="isRerunVisible = false"
      >
        <div slot="content">
          <rerun-automation
            v-if="isRerunVisible"
            :automation-id="automation_id"
            @updateAutomation="updateAutomation"
          />
        </div>
      </prompt>

      <div class="col-12">

        <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
          <p>
            create a new automation using our intuitive automation builder.
          </p>
          <p>
            install and configure pre-built standard automations
          </p>
          <p>
            view a list of your automation, we have configured 3 default automations for you.
          </p>
          <p>
            actions available for each automation, allowing you easy access to them.
          </p>
        </HelpModal>

        <div class="card border-0 shadow-sm mb-3">
          <div
            class="card-header d-flex flex-column flex-md-row justify-content-between align-items-lg-center align-items-md-start"
          >
            <p class="px-0 py-1 m-1">
              Automations
            </p>
            <div class="px-0 py-1 m-0">
              <router-link
                :to="{ name: 'automations.create' }"
                class="btn btn-sm btn-primary m-1"
                v-if="hasPermission('create-automations')"
              >
                Create an automation
              </router-link>
              <router-link
                :to="{ name: 'recipes.index' }"
                class="btn btn-sm btn-light m-1"
                v-if="hasPermission('view-recipes')"
              >
                Install an automation
              </router-link>
            </div>
          </div>
          <api-loader :isLoading="loading">
            <div
              v-if="Object.keys(automations.data).length !== 0"
              class="card-body"
            >
              <data-table
                :headers="headers()"
                sort-url="/automationRule"
                :method="'get'"
                :data="automations.data"
              >
                <template #title="{ item }">
                  {{ item.title }}
                </template>
                <template #event="{ item }">
                  <!-- get event name from given id -->
                  {{ getEventName(item.event) }}
                </template>
                <template #active="{ item }">
                  <button
                    v-tooltip.right="
                      item.active == false
                        ? 'Activate automation'
                        : 'Pause automation'
                    "
                    class="btn activate"
                    :class="classExtraction(item.active)"
                    @click="setLive(item.id, item.active)"
                  >
                    <span>
                      <svg
                        id="circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="7.5" />
                      </svg>
                      <svg
                        id="arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8 8"
                      >
                        <path
                          d="M5.41 4l1.42 1.41a1.004 1.004 0 11-1.42 1.42L4 5.4 2.59 6.82A1.004 1.004 0 111.17 5.4L2.6 4 1.18 2.59A1.004 1.004 0 112.6 1.17L4 2.6l1.41-1.42A1.004 1.004 0 116.83 2.6L5.4 4h.01z"
                        />
                      </svg>
                      <svg
                        id="check"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8 6"
                      >
                        <path
                          d="M1.645 2.246l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42v.01z"
                        />
                      </svg>
                    </span>
                    <ul>
                      <li>Paused</li>
                      <li>Updating</li>
                      <li>Active</li>
                    </ul>
                  </button>
                  <span class="text-muted ml-3">{{ item.log_count }} runs</span>
                </template>
                <template #date="{ item }">
                  {{ item.created_at | formatLocaleDateString }}
                </template>
                <template #actions="{ item }">
                  <div>
                    <router-link
                      :to="{
                        name: 'automations.copy',
                        params: { id: item.id },
                      }"
                      class="btn btn-sm btn-outline-success m-1"
                      v-if="hasPermission('copy-automations')"
                    >
                      Copy
                    </router-link>
                    <router-link
                      :to="{
                        name: 'automations.edit',
                        params: { id: item.id },
                      }"
                      class="btn btn-sm btn-outline-secondary m-1"
                      v-if="hasPermission('edit-automations')"
                    >
                      Edit
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-danger m-1"
                      @click="deleteAutomation(item.id)"
                      v-if="hasPermission('delete-automations')"
                    >
                      Delete
                    </button>
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-secondary m-1"
                      @click="rerunAutomation(item.id)"
                      v-if="hasPermission('rerun-automations')"
                    >
                      Rerun
                    </button>
                  </div>
                </template>
              </data-table>
              <pagination
                :data="automations"
                :limit="3"
                :show-disabled="true"
                align="right"
                @pagination-change-page="getAutomations"
              >
                <span slot="prev-nav" class="text-capitalize"
                  ><i class="fas fa-chevron-left mr-2" /> Prev</span
                >
                <span slot="next-nav" class="text-capitalize"
                  >Next<i class="fas fa-chevron-right ml-2"
                /></span>
              </pagination>
            </div>
            <div v-else class="card-body">
              <p class="text-muted">
                This page shows a list of your automations.
              </p>
              <div class="p-4 rounded border-zip text-center">
                <p class="mb-0">
                  No automations to display
                </p>
              </div>
            </div>
          </api-loader>
        </div>
      </div>
      <div class="col-12">
        <router-link
          :to="{ name: 'automations.activity' }"
          v-if="hasPermission('view-activity')"
          class="btn btn-sm btn-outline-secondary m-1"
        >
          <Activities />
          <span>Automation log</span>
        </router-link>
        <div class="page-help">
          <a @click="openHelp">
            <QuestionCircle />
          </a>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import ApiLoader from "@/components/UI/ApiLoader.vue";
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import DeletePrompt from "@/components/UI/DeletePrompt.vue";
import DeleteAutomation from "@/components/Automation/DeleteAutomation.vue";
import RerunAutomation from "@/components/Automation/RerunAutomation.vue";
import Prompt from "@/components/UI/Prompt.vue";
import { loadWithPager } from "@/helpers/apiConnection";
import events from "@/helpers/events";
import { EventName } from "@/helpers/automationUtilities";
import Permission from "@/utils/permission";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  extends: Permission,
  components: {
    ApiLoader,
    Default,
    DeletePrompt,
    DeleteAutomation,
    DataTable,
    Prompt,
    RerunAutomation,
    QuestionCircle,
    HelpModal,
  },
  async beforeRouteEnter(to, from, next) {
    const res = await loadWithPager(
      "/automationRule",
      "1",
      to.query.sort ?? "",
      to.query.dir ?? "desc"
    );
    if (res) {
      next((vm) => {
        vm.automations = res;
      });
    }
  },
  async beforeRouteUpdate(to) {
    const res = await loadWithPager(
      "/automationRule",
      "1",
      to.query.sort ?? "",
      to.query.dir ?? "desc"
    );
    if (res) {
      this.automations = res;
    }
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      isDeleteModalVisible: false,
      isRerunVisible: false,
      automations: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
      automation_id: "",
      event_name: "",
      helpModal: false,
    };
  },
  methods: {
    headers() {
      return [
        { title: "Title", key: "name", sortable: true, sortKey: "name" },
        { title: "Event type", key: "event", sortable: true, sortKey: "event" },
        { title: "Status", key: "active", sortable: true, sortKey: "active" },
        {
          title: "Created on",
          key: "date",
          sortable: true,
          sortKey: "created_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    async getAutomations(page = 1) {
      this.loading = true;
      const res = await loadWithPager(
        "/automationRule",
        page,
        this.$route.query.sort ?? "",
        this.$route.query.dir ?? "desc"
      );
      if (res) {
        this.automations = res;
      }
      this.loading = false;
    },
    setLive(id, active) {
      const index = this.automations.data.findIndex((item) => {
        return id === item.id;
      });

      this.automations.data[index].active = 2;

      const newVal = active === false;

      jsonAPI
        .put("/automationRule/status/" + this.automations.data[index].id, {
          active: newVal,
        })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          var self = this;
          setTimeout(function() {
            self.automations.data[index].active = newVal;
          }, 2000);
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error",
            type: "error",
          });
        });
    },
    classExtraction(status) {
      var text;
      switch (status) {
        case true:
          text = "loading done";
          break;
        case 2:
          text = "loading";
          break;
        default:
          text = "";
          break;
      }
      return text;
    },
    deleteAutomation(id) {
      this.automation_id = id;
      this.isDeleteModalVisible = true;
    },
    updateAutomation(event) {
      this.automations.data = event;
    },
    rerunAutomation(id) {
      this.automation_id = id;
      this.isRerunVisible = true;
    },
    getEventName(event_id) {
      return EventName(events, event_id);
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    }
  },
};
</script>
