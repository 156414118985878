<template>
  <div>
    <Spinner v-if="isLoading" />
    <div v-if="!isLoading" class="modal-body">
      <div v-if="!rerunSuccess" class="description">
        <h2>Rerun automation</h2>
        <p>
          Rerunning this automation will affect {{ values.orderCount }} orders
          and therefore use {{ values.orderCount }} execution points.
        </p>
        <p>
          You currently have {{ values.executionPoints }} execution points left.
        </p>
        <p v-if="!values.canRun" class="text-danger">
          You don't have enough points to run this automation for all the order
          it will affect.
        </p>
        <p v-else class="text-success">
          You can safely run this automation again
        </p>
      </div>
      <div v-else class="description success">
        <checkmark-circle-line />
      </div>
    </div>
    <div class="modal-footer">
      <button
        v-if="!rerunSuccess"
        class="btn btn-secondary float-left"
        @click="close"
      >
        Cancel
      </button>
      <ButtonLoading
        v-if="!rerunSuccess"
        :loading="loading"
        :class="'btn btn-success push'"
        :title="'Run automation'"
        v-on:click.native="runAutomation"
      />
      <button
        v-if="rerunSuccess"
        class="btn btn-secondary float-left"
        @click="close"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import Spinner from "@/components/UI/Spinner.vue";
import CheckmarkCircleLine from "@/components/Svg/CheckmarkCircleLine";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
export default {
  name: "RerunAutomation",
  components: { CheckmarkCircleLine, Spinner, ButtonLoading },
  props: {
    automationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      rerunSuccess: false,
      values: {
        orderCount: 0,
        executionPoints: 0,
        canRun: false,
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    let uri = "/automationRule/calculate/" + this.automationId;
    let response = {
      orderCount: 0,
      executionPoints: 0,
      canRun: false,
    };
    try {
      response = await jsonAPI.get(uri);
    } catch (e) {
      window.Bus.$emit("flash-message", {
        text: "Error fetching results",
        type: "error",
      });
    }
    this.values = response.data;
    this.isLoading = false;
  },
  methods: {
    close() {
      this.$parent.$emit("close");
    },
    runAutomation() {
      this.loading = true;

      let uri = "/automationRule/run/" + this.automationId;
      jsonAPI
        .put(uri, { id: this.automationId })
        .then(() => {
          this.loading = false;
          this.rerunSuccess = true;
          //this.$parent.$emit('close');
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
          this.loading = true;
        });
    },
  },
};
</script>

<style scoped>
.push {
  margin-left: auto;
}
.success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.success svg {
  fill: #3fdc75;
}
</style>
