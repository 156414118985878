<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <h5>
          Automation activity
          <span v-if="logs.meta.total !== 0" class="text-muted ml-4"
            >{{ logs.meta.total }} logs</span
          >
        </h5>
        <div class="card border-0 shadow-sm mb-4">
          <div v-if="Object.keys(logs.data).length !== 0" class="card-body">
            <data-table
              :headers="headers()"
              sort-url="/automationLog"
              :data="logs.data"
            >
              <template #id="{ item }">
                <log-badge :variant="`${item.active}`">
                  {{ getActiveTitle(item.active) }}
                </log-badge>
              </template>
              <template #action="{ item }">
                {{ item.action }}
              </template>
              <template #event="{ item }">
                <log-badge :variant="item.event">
                  {{ item.event }}
                </log-badge>
              </template>
              <template #date="{ item }">
                {{ item.created_at | formatLocaleDateString }}
              </template>
            </data-table>
            <pagination
              :data="logs"
              :limit="3"
              :show-disabled="true"
              align="right"
              @pagination-change-page="getLogs"
            >
              <span slot="prev-nav" class="text-capitalize"
                ><i class="fas fa-chevron-left mr-2" /> Prev</span
              >
              <span slot="next-nav" class="text-capitalize"
                >Next<i class="fas fa-chevron-right ml-2"
              /></span>
            </pagination>
          </div>
          <div v-else class="card-body">
            <p class="text-muted">
              This page shows a list of the most recent client logs, which occur
              when the automation API is used.
            </p>
            <div class="p-4 rounded border-zip text-center">
              <p class="mb-0">
                No logs to show just yet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import DataTable from "@/components/DataTable/DataTable.vue";
import LogBadge from "@/components/Automation/LogBadge.vue";
import Default from "@/layouts/default.vue";
import jsonAPI from "@/api";
export default {
  components: {
    DataTable,
    Default,
    LogBadge,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/automationLog?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.logs = response.data;
      });
    });
  },
  async beforeRouteUpdate(to) {
    let uri = "/automationLog?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      this.logs = response.data;
    });
  },
  data() {
    return {
      layout: "Default",
      logs: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
    };
  },
  methods: {
    headers() {
      return [
        { title: "Status", key: "id", sortable: true, sortkey: "id" },
        {
          title: "Automation Activity",
          key: "action",
          sortable: true,
          sortkey: "action",
        },
        {
          title: "Automation Trigger",
          key: "event",
          sortable: true,
          sortkey: "event",
        },
        {
          title: "Date & Time",
          key: "date",
          sortable: true,
          sortkey: "created_at",
        },
      ];
    },
    getLogs: function(page = 1) {
      let uri = "/automationLog?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.logs = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    getActiveTitle: (active) => {
      if (active) {
        return "Active";
      }
      return "Test";
    },
  },
};
</script>

<style lang="scss" scoped></style>
