<template>
  <span class="badge badge-pill" :class="getType">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "default",
    },
  },
  computed: {
    getType() {
      switch (this.variant) {
        case "true":
        case "OrderCreated":
          return "badge-success";
        case "OrderUpdated":
          return "badge-secondary";
        case "OrderDeleted":
          return "badge-danger";
        case "false":
          return "badge-warning";
        default:
          return "badge-light";
      }
    },
  },
};
</script>
