var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('h5',[_vm._v(" Automation activity "),(_vm.logs.meta.total !== 0)?_c('span',{staticClass:"text-muted ml-4"},[_vm._v(_vm._s(_vm.logs.meta.total)+" logs")]):_vm._e()]),_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[(Object.keys(_vm.logs.data).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/automationLog","data":_vm.logs.data},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('log-badge',{attrs:{"variant":("" + (item.active))}},[_vm._v(" "+_vm._s(_vm.getActiveTitle(item.active))+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action)+" ")]}},{key:"event",fn:function(ref){
var item = ref.item;
return [_c('log-badge',{attrs:{"variant":item.event}},[_vm._v(" "+_vm._s(item.event)+" ")])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}}],null,false,1018434940)}),_c('pagination',{attrs:{"data":_vm.logs,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.getLogs}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v("Next"),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This page shows a list of the most recent client logs, which occur when the automation API is used. ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No logs to show just yet. ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }